@tailwind base;
@tailwind components;
@tailwind utilities;

.input-container{
    position: relative;
}
.input-before{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.input-after{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}